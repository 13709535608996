import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionResults from "../components/QuestionResults";
import UnansweredQuestionReroute from "../components/UnansweredQuestionReroute";
// import { DimensionValuesContextProvider } from "../store/dimension-values-context";

import classes from "./QuestionResultsPage.module.css";

function QuestionResultsPage(props) {
  //
  // Set a useNavigate() constant.
  const navigate = useNavigate();

  // Get the question_id from the URL.
  let { q } = useParams();

  // Define a useState() to track whether the results page is loading.
  const [isLoading, setIsLoading] = useState(true);

  // Define a useState() to track the raw query data for the results of the
  //   question of focus.
  let [allResults, setAllResults] = useState();

  // Define a useState() to track whether allResults has been initialized upon
  //   loading of the page.
  const [allResultsLoaded, setAllResultsLoaded] = useState(false);

  // Define a useState() to track the raw query data for the response options of
  //   the question of focus.
  let [allOptions, setAllOptions] = useState();

  // Define a useState() to track whether allOptions has been initialized upon
  //   loading of the page.
  const [allOptionsLoaded, setAllOptionsLoaded] = useState(false);

  // Define a useState() to track the raw query data for the user's particular
  //   response to the question of focus.
  let [userResponse, setUserResponse] = useState();

  // Define a useState() to track whether userResponse has been initialized upon
  //   loading of the page.
  const [userResponseLoaded, setUserResponseLoaded] = useState(false);

  // Define a useState() to store the question_text value of the question.
  const [questionText, setQuestionText] = useState();

  // Define a useState() to store the total response_count for the question.
  const [responseCountTotal, setResponseCountTotal] = useState();

  // Define a useState() to track whether the user has responded to the
  //   question.
  const [userHasAnswered, setUserHasAnswered] = useState();

  // Call useEffect() to enable a chunk of code that will query the question
  //   results from the database and get them into a desired format.
  useEffect(() => {
    fetch("https://devbe.polldock.com/api/questionResults/" + q + "/")
    // fetch("http://localhost:8000/api/questionResults/" + q + "/")
      .then(
        (response) => {
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          const ar = dataJson;
          const responseCountTotalProp = ar.reduce(
            (accumulator, obj) => accumulator + obj.response_count,
            0
          );
          const arResponse = {
            ar: ar,
            questionText: ar[0].question_text,
            responseCountTotalProp: responseCountTotalProp,
          };
          return arResponse;
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (arResponse) => {
          setAllResults(arResponse.ar);
          setAllResultsLoaded(true);
          setQuestionText(arResponse.questionText);
          setResponseCountTotal(arResponse.responseCountTotalProp);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }, [q, navigate]);

  // Call useEffect() to enable a chunk of code that will query the question's
  //   available response options from the database. We want all unique
  //   option_id and option_text values.
  useEffect(() => {
    fetch("https://devbe.polldock.com/api/answerOptions/" + q + "/")
    // fetch("http://localhost:8000/api/answerOptions/" + q + "/")
      .then(
        (response) => {
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          const ao = dataJson;
          setAllOptions(ao);
          setAllOptionsLoaded(true);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }, [q, navigate]);

  // Call useEffect() to enable a chunk of code that will query the user's
  //   response to the question of focus.
  useEffect(() => {
    fetch(
      "https://devbe.polldock.com/api/userResponse/" + q + "/" + props.user_id + "/"
      // "http://localhost:8000/api/userResponse/" + q + "/" + props.user_id + "/"
    )
      .then(
        (response) => {
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          let ur = "";
          if (dataJson.length === 0) {
            ur = "--- You have not yet responded to this question! ---";
            setUserHasAnswered(false);
          } else {
            ur = dataJson[0]["option_text"];
            setUserHasAnswered(true);
          }
          setUserResponse(ur);
          setUserResponseLoaded(true);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }, [q, props.user_id, navigate]);

  // Call useEffect() to update isLoading() to false when allResultsLoaded,
  //   allOptionsLoaded, and userResponseLoaded all become true.
  useEffect(() => {
    if (allResultsLoaded && allOptionsLoaded && userResponseLoaded) {
      setIsLoading(false);
    }
  }, [allOptionsLoaded, allResultsLoaded, userResponseLoaded]);

  // If the page is loading, display a "loading" message.
  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  // If the page has finished loading, display the QuestionResults component
  //   populated with the relevant data. Only do so if the user has answered the
  //   question. If not, load the UnansweredQuestionReroute component to reroute
  //   to the corresponding AnswerQuestionPage.
  if (userHasAnswered) {
    return (
      <div className={classes.questionResultsPage_containerDiv}>
        <h1 className={classes.questionResultsPage_h1}>{questionText}</h1>
        <div className={classes.questionResultsPage_underline}></div>
        <QuestionResults
          results={allResults}
          options={allOptions}
          responseCountTotal={responseCountTotal}
          userResponse={userResponse}
          questionText={questionText}
        />
      </div>
    );
  } else {
    return <UnansweredQuestionReroute q={q} />;
  }
}

export default QuestionResultsPage;
