import classes from "./AboutPolldock.module.css";

function AboutPolldock() {
  //
  return (
    <div>
      <div className={classes.aboutPolldock_textBlock}>
        Our mission at Polldock is to help people find out where their views
        stand in relation to their families, friends, and communities, as well
        as to the population as a whole.
      </div>
      <div className={classes.aboutPolldock_textBlock}>
        We aim to do this by presenting our users with a wide variety of simple
        poll questions, ranging in topic from culture to politics to sports and
        beyond. Once a question is answered, the user can see the aggregated
        results of how everyone else has responded to that question. Users can
        browse the large library of questions to pick and choose which they
        would like to answer.
      </div>
      <div className={classes.aboutPolldock_textBlock}>
        If you find a question and its results to be particularly interesting or
        entertaining, please share it with your friends! You can do this by
        sharing the link to the question and by posting a screenshot of the
        results (using our built-in screenshot widget on the results page). The
        more people we have answering our questions, the more we can learn about
        how we all see the world!
      </div>
      <div className={classes.aboutPolldock_textBlock}>
        The site is completely free and very easy to use. We want your
        experience to be convenient, informative, and (most importantly) fun!
        All responses are anonymous. No identifying personal information is
        collected from users.
      </div>
      <div className={classes.aboutPolldock_textBlock}>
        At this time, Polldock is only available to users within the United
        States, but we are looking to expand in the future.
      </div>
      <div className={classes.aboutPolldock_textBlock}>
        We have many poll questions that are available for you to explore now.
        New ones are being added all the time, so be sure to check back
        regularly.
      </div>
      <div className={classes.aboutPolldock_bottomPadding}></div>
    </div>
  );
}

export default AboutPolldock;
