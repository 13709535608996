import { createContext } from "react";

// Define a constant array of all sex values.
const sexesAll = ["Male", "Female"];

// Define a constant array of all birth decade values.
const decadesAll = [
  "2000s",
  "1990s",
  "1980s",
  "1970s",
  "1960s",
  "1950s",
  "1940s",
  "1930s",
  "pre-1930s",
];

// Define a constant array of all location values.
const locationsAll = {
  locationsLong: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
  locationsLongToShort: {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "District of Columbia": "DC",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  },
};

// Define a constant array of colors to be used for bars in bar chart
//   visualizations.
const colors = [
  "#D81B60",
  "#1E88E5",
  "#FFC107",
  "#6EBB84",
  "#64348A",
  "#93DCF3",
  "#01584A",
];

// Define a constant array of light colors. These should all be colors that are
//   in the colors array above. We identify these so that we may use black text
//   when we overlay labels on bars of this color.
const lightColors = ["#FFC107", "#93DCF3"];

// Define the context component for visualization parameters.
const DimensionValuesContext = createContext({
  sexesAll: sexesAll,
  decadesAll: decadesAll,
  locationsAll: locationsAll,
  colors: colors,
  lightColors: lightColors,
});

export function DimensionValuesContextProvider(props) {
  //
  // Define the context object, using all of the default values defined above.
  //   These values are not intended to change.
  const context = {
    sexesAll: sexesAll,
    decadesAll: decadesAll,
    locationsAll: locationsAll,
    colors: colors,
    lightColors: lightColors,
  };

  // Return the provider for the context component wrapping children components.
  return (
    <DimensionValuesContext.Provider value={context}>
      {props.children}
    </DimensionValuesContext.Provider>
  );
}

export default DimensionValuesContext;
