import NavigationBar from "./NavigationBar";
// import NavigationBar from "./NavigationBarHamburger";

import classes from "./Layout.module.css";

function Layout(props) {
  return (
    <div className={classes.layout_containerDiv}>
      <NavigationBar />
      <main>
        <div className={classes.layout_mainContainer}>
          {props.children}
        </div>
      </main>
    </div>
  );
}

export default Layout;
