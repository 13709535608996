import { useState, useContext, useRef } from "react";
import { Turnstile } from "@marsidev/react-turnstile";
import DimensionValuesContext from "../store/dimension-values-context";
import CsrfToken from "./CsrfToken";

import classes from "./NewUserForm.module.css";

function NewUserForm(props) {
  //
  // Create a constant to store DimensionValuesContext.
  const dimensionValues = useContext(DimensionValuesContext);

  // Create useRef() instances for each field in the form and for the form
  //   itself. Also create an instance for the Turnstile widget.
  const userInfoFormRef = useRef();
  const turnstileWidgetRef = useRef();
  const sexInputRef = useRef();
  const decadeInputRef = useRef();
  const locationInputRef = useRef();

  // Define a useState() to track the status of the Turnstile widget.
  const [turnstileStatus, setTurnstileStatus] = useState();

  // Define a function to receive the demographic info input by the user, and
  //   pass it to another function from the parent component that creates a new
  //   record in the User model containing the new user info, retrieves the user
  //   ID value corresponding to the new record, and stores it in a cookie.
  async function submitNewUserHandler(event) {
    //
    // Prevent the default browser behavior. This is needed to prevent the
    //   browser from reloading upon submit.
    event.preventDefault();

    // Define constants to capture the current values of the form selections.
    const sexInputEntered = sexInputRef.current.value;
    const decadeInputEntered = decadeInputRef.current.value;
    const locationInputEntered = locationInputRef.current.value;

    // Get the Turnstile token value.
    const formData = new FormData(userInfoFormRef.current);
    const token = formData.get("cf-turnstile-response");

    // Remove the Turnstile widget.
    turnstileWidgetRef.current?.remove();

    // Define an object to store the entered form values.
    const userInfoSubmitted = {
      sex: sexInputEntered,
      decade: decadeInputEntered,
      location: locationInputEntered,
      turnstileToken: token,
    };

    // Call the onAddNewUser() function passed from the parent element. This
    //   will add the new user record to the database and retrieve the ID value
    //   for that new record.
    props.onAddNewUser(userInfoSubmitted);
  }

  // Return components.
  return (
    <div>
      <form
        id="userInfoForm"
        name="userInfoForm"
        method="POST"
        onSubmit={submitNewUserHandler}
        ref={userInfoFormRef}
      >
        <CsrfToken />
        <div className={classes.newUserForm_infoPrompt}>
          Thank you for visiting Polldock! In order to provide the best
          experience, we ask that each user provide some generic demographic
          information. Note, we DO NOT collect indentifying or sensitive
          information from our users. Please see our{" "}
          <strong>Privacy Policy</strong> below for more information.
        </div>
        <div className={classes.newUserForm_selectContainer}>
          <div className={classes.newUserForm_selectDiv}>
            <label
              htmlFor="sexInput"
              className={classes.newUserForm_selectLabel}
            >
              Sex:
            </label>
            <select
              id="sexInput"
              form="userInfoForm"
              className={classes.newUserForm_select_sex}
              required
              defaultValue=""
              ref={sexInputRef}
            >
              <option value="" disabled hidden></option>
              {dimensionValues.sexesAll.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.newUserForm_selectDiv}>
            <label
              htmlFor="decadeInput"
              className={classes.newUserForm_selectLabel}
            >
              Decade of birth:
            </label>
            <select
              id="decadeInput"
              form="userInfoForm"
              className={classes.newUserForm_select_decade}
              required
              defaultValue=""
              ref={decadeInputRef}
            >
              <option value="" disabled hidden></option>
              {dimensionValues.decadesAll.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.newUserForm_selectDiv}>
            <label
              htmlFor="locationInput"
              className={classes.newUserForm_selectLabel}
            >
              Location:
            </label>
            <select
              id="locationInput"
              form="userInfoForm"
              className={classes.newUserForm_select_location}
              required
              defaultValue=""
              ref={locationInputRef}
            >
              <option value="" disabled hidden></option>
              {dimensionValues.locationsAll.locationsLong.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={classes.newUserForm_infoPrompt}>
          This site is for users who are 18 and older. Please check the box
          below to proceed.
        </div>
        <div className={classes.newUserForm_checkboxDiv}>
          <input
            type="checkbox"
            id="age18Attest"
            form="userInfoForm"
            className={classes.newUserForm_checkbox}
            required
          />
          <label
            htmlFor="age18Attest"
            className={classes.newUserForm_checkboxLabel}
          >
            <strong>I am at least 18 years old.</strong> (Required to proceed.)
          </label>
        </div>
        <div className={classes.newUserForm_infoPrompt}>
          Polldock uses cookies that allow us to bring you the most engaging and
          enjoyable experience. Use of Polldock requires your acceptance of all
          cookies. Please check the box below before clicking Submit. For more
          information on how we use cookies, please review our{" "}
          <strong>Privacy Policy</strong> below.
        </div>
        <div className={classes.newUserForm_checkboxDiv}>
          <input
            type="checkbox"
            id="acceptCookies"
            form="userInfoForm"
            className={classes.newUserForm_checkbox}
            required
          />
          <label
            htmlFor="acceptCookies"
            className={classes.newUserForm_checkboxLabel}
          >
            <strong>I accept all cookies.</strong> (Required to proceed.)
          </label>
        </div>
        <div className={classes.newUserForm_infoPrompt}>
          This site uses Cloudflare's Turnstile technology to verify that you
          are a person and not a robot. You can find more information about
          Turnstile on their{" "}
          <a
            className={classes.newUswerForm_infoPromptLink}
            href={"https://www.cloudflare.com/products/turnstile/"}
          >
            product page
          </a>{" "}
          and{" "}
          <a
            className={classes.newUswerForm_infoPromptLink}
            href={"https://developers.cloudflare.com/turnstile/"}
          >
            technical documentation
          </a>
          . By proceeding, you consent to Turnstile being used to verify that
          you are a person.
        </div>
        {turnstileStatus === undefined ? (
          <div className={classes.newUserForm_submitDiv}></div>
        ) : (
          <div className={classes.newUserForm_submitDiv}>
            <button className={classes.newUserForm_submit}>Submit</button>
          </div>
        )}
        <div className={classes.newUserForm_turnstileContainer}>
          <Turnstile
            siteKey="0x4AAAAAAAI6m3wcWooJSSYH"
            ref={turnstileWidgetRef}
            onError={() => setTurnstileStatus("error")}
            onExpire={() => setTurnstileStatus("expired")}
            onSuccess={() => setTurnstileStatus("solved")}
          />
        </div>
      </form>
    </div>
  );
}

export default NewUserForm;
