import { useState, useEffect } from "react";
import QuestionList from "../components/QuestionList";
import { useNavigate, useParams } from "react-router-dom";

function QuestionListPage(props) {
  //
  // Set a useNavigate() constant.
  const navigate = useNavigate();

  // Get the questions category from the URL.
  let { cat } = useParams();

  // Set a useState() that indicates whether the page is loading.
  const [isLoading, setIsLoading] = useState(true);

  // Set a useState() to store the list of questions to be queried.
  const [allQuestions, setAllQuestions] = useState();

  // Get the list of all questions including an indicator as to whether the user
  //   has answered each. Return results as JSON object.
  useEffect(() => {
    fetch("https://devbe.polldock.com/api/questionList/" + props.user_id + "/")
    // fetch("http://localhost:8000/api/questionList/" + props.user_id + "/")
      .then(
        (response) => {
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          const aq = dataJson;
          setIsLoading(false);
          setAllQuestions(aq);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  }, [props.user_id, navigate]);

  // Return a loading message while the query runs.
  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  // Set the cat parameter to "All" if the category prop is "All". Note: this
  //   prop will only be passed if the QuestionListPage is being routed to with
  //   the URL that relates to all questions, as opposed to the URL that relates
  //   to a specific category.
  if (props.category === "All") {
    cat = "All";
  }

  // Return the QuestionList component for all categories or for the specified
  //   category.
  return (
    <div>
      <div>
        <QuestionList questions={allQuestions} category={cat} />
      </div>
    </div>
  );
}

export default QuestionListPage;
