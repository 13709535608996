import { useState, createRef } from "react";
import FilterResultsForm from "./FilterResultsForm";
import ResultsOverview from "./visualizations/ResultsOverview";
import ResultsByDimension from "./visualizations/ResultsByDimension";
import * as dfjs from "dataframe-js";
import dropdownChevron from "../images/dropdown_chevron_black.png";
import { useScreenshot, createFileName } from "use-react-screenshot";
import siteNameLogo from "../images/site_name_logo.png";

import classes from "./QuestionResults.module.css";

function QuestionResults(props) {
  //
  // Store props.options in a variable.
  const options = props.options;

  // Define a useState() to track the current results to be displayed based on
  //   user filter input.
  const [resultsDisplay, setResultsDisplay] = useState(
    new dfjs.DataFrame(props.results)
  );

  // Define a useState() to store the filtered response_count for the question.
  const [responseCountFiltered, setResponseCountFiltered] = useState(
    props.responseCountTotal
  );

  // Define a dataframe of all results.
  const resultsAllDf = new dfjs.DataFrame(props.results);

  // Set default arrays for sex, decade, and location filtering options as the
  //   unique values for each dimension that exist in props.results.
  const sexOptions = resultsAllDf
    .distinct("sex")
    .toArray()
    .map((x) => x[0])
    .sort();
  const decadeOptions = resultsAllDf
    .distinct("decade")
    .toArray()
    .map((x) => x[0])
    .sort();
  const locationOptions = resultsAllDf
    .distinct("location")
    .toArray()
    .map((x) => x[0])
    .sort();

  // Set an array of checked values for each filter as a useState() with an
  //   initial value of an array that includes all available options.
  const [sexChecked, setSexChecked] = useState(sexOptions);
  const [decadeChecked, setDecadeChecked] = useState(decadeOptions);
  const [locationChecked, setLocationChecked] = useState(locationOptions);

  // Define handler functions to change the useState() array of the checked
  //   values for each dimension. These will be called each time any option for
  //   a given dimension is either checked or unchecked (i.e. changed).
  function sexCheckedUpdateHandler(event) {
    if (event.target.checked) {
      if (sexChecked.indexOf(event.target.value) === -1) {
        const newSexChecked = sexChecked.concat(event.target.value);
        setSexChecked(newSexChecked);
      } else {
      }
    } else {
      if (sexChecked.indexOf(event.target.value) !== -1) {
        const newSexChecked = sexChecked.filter(
          (entry) => entry !== event.target.value
        );
        setSexChecked(newSexChecked);
      } else {
      }
    }
  }
  function decadeCheckedUpdateHandler(decadeCheckedCurrent) {
    setDecadeChecked(decadeCheckedCurrent);
  }
  function locationCheckedUpdateHandler(locationCheckedCurrent) {
    setLocationChecked(locationCheckedCurrent);
  }

  // Define handler functions to filter the results data set based on the
  //   user-selected values of the sex, decade, and location filters.
  function filterResultsSexHandler(sexChoices, resultsData) {
    //
    // If sexChoices is empty, alert the user to choose at least one value.
    //   Otherwise, filter resultsData based on sexChoices.
    if (sexChoices.length === 0) {
      alert("Please select at least one option for Sex.");
      return [];
    } else {
      return resultsData.filter((row) => sexChoices.includes(row.get("sex")));
    }
  }
  function filterResultsDecadeHandler(decadeChoices, resultsData) {
    //
    // If decadeChoices is empty, alert the user to choose at least one value.
    //   Otherwise, filter resultsData based on decadeChoices.
    if (decadeChoices.length === 0) {
      alert("Please select at least one option for Birth Decade.");
      return [];
    } else {
      return resultsData.filter((row) =>
        decadeChoices.includes(row.get("decade"))
      );
    }
  }
  function filterResultsLocationHandler(locationChoices, resultsData) {
    //
    // If locationChoices is empty, alert the user to choose at least one value.
    //   Otherwise, filter resultsData based on locationChoices.
    if (locationChoices.length === 0) {
      alert("Please select at least one option for Location.");
      return [];
    } else {
      return resultsData.filter((row) =>
        locationChoices.includes(row.get("location"))
      );
    }
  }

  // Define a handler function to filter results based on user-selected
  //   filtering options for each dimension (to be selected in the form
  //   component called below). Each of the dimension filtering functions that
  //   is defined above will be called within this function.
  function filterResultsHandler(
    sexSelections,
    decadeSelections,
    locationSelections,
    allResults
  ) {
    //
    // Apply all filtering sequentially to allResults.
    const filteredResults = filterResultsLocationHandler(
      locationSelections,
      filterResultsDecadeHandler(
        decadeSelections,
        filterResultsSexHandler(sexSelections, allResults)
      )
    );

    // If filteredResults is an empty dataframe, set resultsDisplay as an empty
    //   array. Otherwise, set resultsDisplay as filteredResults.
    //   filteredResults may be an empty array as well (in the event that one of
    //   the filter categories options are all unchecked). In such a case, set
    //   resultsDisplay to [].
    if (filteredResults.length !== 0) {
      if (filteredResults.dim()[0] === 0) {
        setResultsDisplay([]);
      } else {
        setResultsDisplay(filteredResults);
      }
    } else {
      setResultsDisplay([]);
    }

    // Set responseCountFiltered as the sum of response_count in
    //   filteredResults. If filteredResults has no rows, set to 0.
    if (filteredResults.length === 0) {
      setResponseCountFiltered(0);
    } else {
      setResponseCountFiltered(filteredResults.stat.sum("response_count"));
    }
  }

  // Define a useState() for tracking whether to show or hide the filters.
  const [showFilters, setShowFilters] = useState(false);

  // Define useStates() for tracking whether to show or hide by-dimension
  //   results.
  const [showBySex, setShowBySex] = useState(false);
  const [showByDecade, setShowByDecade] = useState(false);
  const [showByLocation, setShowByLocation] = useState(false);

  // Define a function to toggle the value of showFilters when the filters
  //   heading div is clicked.
  const toggleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  // Define a function to toggle the value of the show/hide by-dimenion
  //   useState() values.
  const toggleShowBySex = () => {
    setShowBySex(!showBySex);
  };
  const toggleShowByDecade = () => {
    setShowByDecade(!showByDecade);
  };
  const toggleShowByLocation = () => {
    setShowByLocation(!showByLocation);
  };

  // Create a ref pointing to the container to be screenshot.
  const refScreenshot = createRef(null);

  // Create a useScreenshot() instance.
  const [image, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  // Define functions for capturing a screenshot of the results.
  const downloadScreenshotHelper = (
    image,
    { name = "poll_results", extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  const downloadScreenshot = () =>
    takeScreenshot(refScreenshot.current).then(downloadScreenshotHelper);

  // Return components.
  return (
    <div>
      <div>
        {resultsDisplay.length === 0 ? (
          <p>
            There are no results that satisfy your filter selections. Please
            broaden your selections.
          </p>
        ) : (
          <div>
            <ResultsOverview
              resultsDisplay={resultsDisplay}
              options={options}
              questionText={props.questionText}
            />
          </div>
        )}
      </div>
      <div className={classes.questionResults_responseCountsDiv}>
        <div className={classes.questionResults_responseCounts}>
          {"Total responses: "}
          <strong>{props.responseCountTotal.toLocaleString()}</strong>
        </div>
        <div className={classes.questionResults_responseCounts}>
          {"Filtered responses: "}
          <strong>{responseCountFiltered.toLocaleString()}</strong>
        </div>
      </div>
      <div className={classes.questionResults_userResponse}>
        {"Your response: "} <strong>{props.userResponse}</strong>
      </div>
      <button
        className={classes.questionResults_screenshotButton}
        onClick={downloadScreenshot}
      >
        Download results snapshot
      </button>
      <div className={classes.questionResults_filtersContainer}>
        <div
          className={classes.questionResults_collapseHeadingDiv}
          onClick={toggleShowFilters}
        >
          <div className={classes.questionResults_collapseChevronDiv}>
            <img
              className={
                showFilters
                  ? classes.questionResults_collapseChevronImgExpanded
                  : classes.questionResults_collapseChevronImgCollapsed
              }
              src={dropdownChevron}
              alt={""}
            />
          </div>
          <h2 className={classes.questionResults_collapseHeading}>Filters</h2>
        </div>
        <div
          className={
            showFilters
              ? classes.questionResults_divExpanded
              : classes.questionResults_divCollapsed
          }
        >
          <div>
            <FilterResultsForm
              sexOptions={sexOptions}
              sexCheckedUpdateHandler={sexCheckedUpdateHandler}
              decadeOptions={decadeOptions}
              decadeCheckedUpdateHandler={decadeCheckedUpdateHandler}
              locationOptions={locationOptions}
              locationCheckedUpdateHandler={locationCheckedUpdateHandler}
            />
          </div>
          <div>
            <button
              className={classes.questionResults_applyFilters}
              onClick={() => {
                filterResultsHandler(
                  sexChecked,
                  decadeChecked,
                  locationChecked,
                  resultsAllDf
                );
              }}
            >
              Apply filter selections
            </button>
          </div>
        </div>
      </div>
      <div>
        {resultsDisplay.length === 0 ? (
          <p>
            There are no results that satisfy your filter selections. Please
            broaden your selections.
          </p>
        ) : (
          <div>
            <div className={classes.questionResults_byDimensionContainer}>
              <div
                className={classes.questionResults_collapseHeadingDiv}
                onClick={toggleShowBySex}
              >
                <div className={classes.questionResults_collapseChevronDiv}>
                  <img
                    className={
                      showBySex
                        ? classes.questionResults_collapseChevronImgExpanded
                        : classes.questionResults_collapseChevronImgCollapsed
                    }
                    src={dropdownChevron}
                    alt={""}
                  />
                </div>
                <h2 className={classes.questionResults_collapseHeading}>
                  Results by sex
                </h2>
              </div>
              <div
                className={
                  showBySex
                    ? classes.questionResults_divExpanded
                    : classes.questionResults_divCollapsed
                }
              >
                <ResultsByDimension
                  resultsDisplay={resultsDisplay}
                  options={options}
                  dimension={"sex"}
                />
              </div>
            </div>
            <div className={classes.questionResults_byDimensionContainer}>
              <div
                className={classes.questionResults_collapseHeadingDiv}
                onClick={toggleShowByDecade}
              >
                <div className={classes.questionResults_collapseChevronDiv}>
                  <img
                    className={
                      showByDecade
                        ? classes.questionResults_collapseChevronImgExpanded
                        : classes.questionResults_collapseChevronImgCollapsed
                    }
                    src={dropdownChevron}
                    alt={""}
                  />
                </div>
                <h2 className={classes.questionResults_collapseHeading}>
                  Results by decade
                </h2>
              </div>
              <div
                className={
                  showByDecade
                    ? classes.questionResults_divExpanded
                    : classes.questionResults_divCollapsed
                }
              >
                <ResultsByDimension
                  resultsDisplay={resultsDisplay}
                  options={options}
                  dimension={"decade"}
                />
              </div>
            </div>
            <div className={classes.questionResults_byDimensionContainer}>
              <div
                className={classes.questionResults_collapseHeadingDiv}
                onClick={toggleShowByLocation}
              >
                <div className={classes.questionResults_collapseChevronDiv}>
                  <img
                    className={
                      showByLocation
                        ? classes.questionResults_collapseChevronImgExpanded
                        : classes.questionResults_collapseChevronImgCollapsed
                    }
                    src={dropdownChevron}
                    alt={""}
                  />
                </div>
                <h2 className={classes.questionResults_collapseHeading}>
                  Results by location
                </h2>
              </div>
              <div
                className={
                  showByLocation
                    ? classes.questionResults_divExpanded
                    : classes.questionResults_divCollapsed
                }
              >
                <ResultsByDimension
                  resultsDisplay={resultsDisplay}
                  options={options}
                  dimension={"location"}
                />
              </div>
            </div>
            <div
              className={classes.questionResults_bottomBufferContainer}
            ></div>
          </div>
        )}
      </div>
      {/* Content for screenshot. */}
      <div className={classes.questionResults_screenshot_containerOuter}>
        <div
          className={classes.questionResults_screenshot_containerInner}
          ref={refScreenshot}
        >
          <h1 className={classes.questionResults_screenshot_h1}>
            {props.questionText}
          </h1>
          <div className={classes.questionResults_screenshot_underline}></div>
          <ResultsOverview resultsDisplay={resultsAllDf} options={options} />
          <div className={classes.questionResults_screenshot_footerContainer}>
            <img
              className={classes.questionResults_screenshot_nameLogoImg}
              src={siteNameLogo}
              alt={""}
            />
          </div>
          <div className={classes.questionResults_screenshot_nameTextDiv}>
            polldock.com
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionResults;
