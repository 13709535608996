import { useContext, useState, useEffect } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import UserIdContext from "../store/user-id-context";
import NewUserPage from "./NewUserPage";
import Layout from "../components/layout/Layout";
import QuestionListPage from "./QuestionListPage";
import RandomQuestionPage from "./RandomQuestionPage";
import AnswerQuestionPage from "./AnswerQuestionPage";
import QuestionResultsPage from "./QuestionResultsPage";
import AboutPolldockPage from "./AboutPolldockPage";
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";

function HomePage() {
  //
  // Set a useNavigate() constant.
  const navigate = useNavigate();

  // Create a constant to store UserIdContext.
  const userIdRetrieval = useContext(UserIdContext);

  // Set a useState() that indicates whether the page is loading.
  const [isLoading, setIsLoading] = useState(true);

  // Call useEffect() to check the database for a value corresponding to
  //   userNumberCookie, if userNumberCookie is defined.
  useEffect(() => {
    //
    // Call the view to obtain the userId from the session, if it exists.
    fetch("https://devbe.polldock.com/api/sessionUser/", {
    // fetch("http://localhost:8000/api/sessionUser/", {
      method: "GET",
      credentials: "include",
    })
      .then(
        (response) => {
          //
          // Convert the returned data to JSON.
          return response.json();
        },
        () => {
          navigate("/error/", { replace: true });
        }
      )
      .then(
        (dataJson) => {
          //
          // If the response contains a record, set the userId useState() equal to
          //   the id property in the data. Otherwise, set userID useState() to -1.
          if (dataJson.length > 0) {
            userIdRetrieval.updateUserId(dataJson[0].id);
          } else {
            userIdRetrieval.updateUserId(-1);
          }

          // Set isLoading to false.
          setIsLoading(false);
        },
        () => {
          navigate("/error/", { replace: true });
        }
      );
  });

  // If isLoading is true, return a "loading" message. Otherwise, continue on to
  //   return the desired page content.
  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  // Check if the value of userId is -1. If it is, return the NewUserPage
  //   component. If not, check if it is a positive number. If so, return the
  //   main page components. Otherwise, return a simple message stating that
  //   something went wrong.
  if (userIdRetrieval.userId === -1) {
    //
    // Return the NewUserPage component.
    return <NewUserPage />;
  } else if (userIdRetrieval.userId > 0) {
    //
    // Return the main page components.
    return (
      <Layout>
        <Routes>
          <Route
            path="all-questions"
            element={
              <QuestionListPage
                user_id={userIdRetrieval.userId}
                category={"All"}
              />
            }
          />
          <Route
            path="category/:cat"
            element={<QuestionListPage user_id={userIdRetrieval.userId} />}
          />
          <Route
            path="random-question"
            element={<RandomQuestionPage user_id={userIdRetrieval.userId} />}
          />
          <Route
            path="answer-question/:q"
            element={<AnswerQuestionPage user_id={userIdRetrieval.userId} />}
          />
          <Route
            path="question-results/:q"
            element={<QuestionResultsPage user_id={userIdRetrieval.userId} />}
          />
          <Route path="about-polldock" element={<AboutPolldockPage />} />
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="*"
            element={
              <QuestionListPage
                user_id={userIdRetrieval.userId}
                category={"All"}
              />
            }
          />
        </Routes>
      </Layout>
    );
  } else {
    return (
      <div>
        Something went wrong. Please refresh the page or try again later.
      </div>
    );
  }
}

export default HomePage;
