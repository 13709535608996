import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { CategoryNameContextProvider } from "./store/category-name-context";
import { FilterSortContextProvider } from "./store/filter-sort-context";
import { DimensionValuesContextProvider } from "./store/dimension-values-context";
import { UserIdContextProvider } from "./store/user-id-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CategoryNameContextProvider>
    <FilterSortContextProvider>
      <DimensionValuesContextProvider>
        <UserIdContextProvider>
          <HashRouter>
            <App />
          </HashRouter>
        </UserIdContextProvider>
      </DimensionValuesContextProvider>
    </FilterSortContextProvider>
  </CategoryNameContextProvider>
);
