import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NewUserForm from "../components/NewUserForm";
import { getCookie } from "../components/CsrfToken";
import AboutPolldock from "../components/AboutPolldock";
import TermsAndConditions from "../components/TermsAndConditions";
import PrivacyPolicy from "../components/PrivacyPolicy";
import siteName from "../images/site_name.png";
import siteLogo from "../images/site_logo.png";
import dropdownChevron from "../images/dropdown_chevron_black.png";

import classes from "./NewUserPage.module.css";

function NewUserPage() {
  //
  // Create a useNavigate instance.
  const navigate = useNavigate();

  // Get the CSRF token from Django's csrf_token cookie.
  const csrf_token = getCookie("csrftoken");

  // Get the pathname of the page's current location (i.e. the URL in the
  //   browser's address bar).
  const currentLocation = useLocation();
  const clPath = currentLocation.pathname;

  // Define a path to navigate to upon submittal of the NewUserForm. If the
  //   current location is "/home/" or "/home", set our new value equal to the
  //   index "/". Otherwise set it to "/home/". We initially wanted to simply
  //   navigate to "/home/" upon submittal, but when the current location is
  //   "/home/" or "/home" and we are also navigating to "/home/", the page does
  //   not appear to refresh upon submittal. It simply stays on the new user
  //   page (though the new user record does get added to the database).
  var navigateTo;
  if ((clPath === "/home/") | (clPath === "/home")) {
    navigateTo = "/";
  } else {
    navigateTo = "/home/";
  }

  // Define a function to receive the new user info obtained in the new uers
  //   form, create a new record in the User model containing that info,
  //   retrieve the user ID value corresponding to the new record, and store
  //   that new ID value in a cookie.
  function addNewUserHandler(userInfo) {
    //
    // Call the Django REST API view to post the new user record to the database
    //   and to retrieve the new user ID value that is created as a result.
    fetch(
      "https://devbe.polldock.com/api/newUser/" +
      // "http://localhost:8000/api/newUser/" +
        userInfo.sex +
        "/" +
        userInfo.decade +
        "/" +
        userInfo.location +
        "/" +
        userInfo.turnstileToken +
        "/",
      {
        method: "GET",
        headers: {
          "X-CSRFToken": csrf_token,
        },
        credentials: "include",
      }
    ).then(
      (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          navigate(navigateTo, { replace: true });
        } else {
          navigate("/error/", { replace: true });
        }
      },
      () => {
        navigate("/error/", { replace: true });
      }
    );
  }

  // Define a useState() for tracking whether to show or hide the each of the
  //   informational sections.
  const [showAboutPolldock, setShowAboutPolldock] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  // Define a function to toggle the value of each of the above useStates() when
  //   the corresponding heading div is clicked.
  const toggleShowAboutPolldock = () => {
    setShowAboutPolldock(!showAboutPolldock);
  };
  const toggleShowTermsAndConditions = () => {
    setShowTermsAndConditions(!showTermsAndConditions);
  };
  const toggleShowPrivacyPolicy = () => {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  };

  // Return components.
  return (
    <div>
      <header>
        <div className={classes.newUserPage_titleContainer}>
          <div className={classes.newUserPage_titleNameDiv}>
            <img
              className={classes.newUserPage_titleNameImg}
              src={siteName}
              alt={""}
            />
          </div>
          <div className={classes.newUserPage_titleLogoDiv}>
            <img
              className={classes.newUserPage_titleLogoImg}
              src={siteLogo}
              alt={""}
            />
          </div>
        </div>
      </header>
      <main>
        <div className={classes.newUserPage_mainContainer}>
          <h1 className={classes.newUserPage_h1}>Welcome!</h1>
          <div className={classes.newUserPage_formDiv}>
            <NewUserForm onAddNewUser={addNewUserHandler} />
          </div>
          <div className={classes.newUserPage_collapseSectionContainer}>
            <div
              className={classes.newUserPage_collapseHeadingDiv}
              onClick={toggleShowAboutPolldock}
            >
              <div className={classes.newUserPage_collapseChevronDiv}>
                <img
                  className={
                    showAboutPolldock
                      ? classes.newUserPage_collapseChevronImgExpanded
                      : classes.newUserPage_collapseChevronImgCollapsed
                  }
                  src={dropdownChevron}
                  alt={""}
                />
              </div>
              <h2 className={classes.newUserPage_collapseHeading}>
                About Polldock
              </h2>
            </div>
            <div
              className={
                showAboutPolldock
                  ? classes.newUserPage_divExpanded
                  : classes.newUserPage_divCollapsed
              }
            >
              <div>
                <AboutPolldock />
              </div>
            </div>
          </div>
          <div className={classes.newUserPage_collapseSectionContainer}>
            <div
              className={classes.newUserPage_collapseHeadingDiv}
              onClick={toggleShowTermsAndConditions}
            >
              <div className={classes.newUserPage_collapseChevronDiv}>
                <img
                  className={
                    showTermsAndConditions
                      ? classes.newUserPage_collapseChevronImgExpanded
                      : classes.newUserPage_collapseChevronImgCollapsed
                  }
                  src={dropdownChevron}
                  alt={""}
                />
              </div>
              <h2 className={classes.newUserPage_collapseHeading}>
                Terms and Conditions
              </h2>
            </div>
            <div
              className={
                showTermsAndConditions
                  ? classes.newUserPage_divExpanded
                  : classes.newUserPage_divCollapsed
              }
            >
              <div>
                <TermsAndConditions />
              </div>
            </div>
          </div>
          <div className={classes.newUserPage_collapseSectionContainer}>
            <div
              className={classes.newUserPage_collapseHeadingDiv}
              onClick={toggleShowPrivacyPolicy}
            >
              <div className={classes.newUserPage_collapseChevronDiv}>
                <img
                  className={
                    showPrivacyPolicy
                      ? classes.newUserPage_collapseChevronImgExpanded
                      : classes.newUserPage_collapseChevronImgCollapsed
                  }
                  src={dropdownChevron}
                  alt={""}
                />
              </div>
              <h2 className={classes.newUserPage_collapseHeading}>
                Privacy Policy
              </h2>
            </div>
            <div
              className={
                showPrivacyPolicy
                  ? classes.newUserPage_divExpanded
                  : classes.newUserPage_divCollapsed
              }
            >
              <div>
                <PrivacyPolicy />
              </div>
            </div>
          </div>
          <div className={classes.newUserPage_bottomBufferContainer}></div>
        </div>
      </main>
    </div>
  );
}

export default NewUserPage;
